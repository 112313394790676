import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Wallet.module.sass";
import Icon from "components/Icon";
import { useSigningClient } from "app/cosmwasm";
import { useAppSelector } from "app/hooks";
import { selectCurrentWallet } from "app/reducers/auth.reducers";
import { getShortAddress } from "app/methods";
import { useNavigate } from "react-router-dom";
type WalletProps = {
  onDisconnect: () => void;
};

const Wallet = ({ onDisconnect }: WalletProps) => {
  const naviagate = useNavigate();
  const walletAddress = useAppSelector(selectCurrentWallet);
  const {
    connectWallet: connectToCoreum,
    disconnect: disconnectFromCoreum,
  }: any = useSigningClient();

  const actions = [
    {
      title: "Disconnect",
      icon: "close-square",
      onClick: () => {
        disconnectFromCoreum();
        onDisconnect();
      },
    },
    {
      title: "Manage Wallet",
      icon: "settings-alt",
      onClick: () => {
        naviagate("/account#wallet");
        onDisconnect();
      },
    },
  ];

  return (
    <div className={styles.wallet}>
      <div className={styles.head}>
        <div className={styles.title}>Connected wallet</div>
        <div className={styles.actions}>
          {actions.map((action: any, index: number) =>
            action.onClick ? (
              <button
                className={styles.action}
                onClick={action.onClick}
                key={index}
              >
                <Icon name={action.icon} />
                {action.title}
              </button>
            ) : (
              <Link to={action.url} key={index}>
                <span className={styles.action}>
                  <Icon name={action.icon} />
                  {action.title}
                </span>
              </Link>
            )
          )}
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.code}>{getShortAddress(walletAddress)}</div>
        <div className={cn("h3", styles.line)}>
          {/* <div className={styles.crypto}>3.345 ETH</div> */}
          {/* <div className={styles.price}>$5,448</div> */}
        </div>
      </div>
    </div>
  );
};

export default Wallet;
