import { useEffect } from "react";
import { useSigningClient } from "app/cosmwasm";
export const KeplrKeyStoreChangeListener = () => {
  const { connectWallet: connectToCoreum }: any = useSigningClient();
  useEffect(() => {
    const handleKeyStoreChange = async () => {
      console.log(
        "Key store in Keplr is changed. You may need to refetch the account info."
      );
      const wallet_type = window.localStorage.getItem("wallet_type");
      if (wallet_type) {
        if (wallet_type === "keplr") {
          await connectToCoreum(wallet_type);
        }
      }
    };

    window.addEventListener("keplr_keystorechange", handleKeyStoreChange);
    return () => {
      window.removeEventListener("keplr_keystorechange", handleKeyStoreChange);
    };
  }, []);

  return null;
};

export const LeapKeyStoreChangeListener = () => {
  const { connectWallet: connectToCoreum }: any = useSigningClient();
  useEffect(() => {
    const handleKeyStoreChange = async () => {
      console.log(
        "Key store in Leap is changed. You may need to refetch the account info."
      );
      const wallet_type = window.localStorage.getItem("wallet_type");
      if (wallet_type) {
        if (wallet_type === "leap") {
          await connectToCoreum(wallet_type);
        }
      }
    };

    window.addEventListener("leap_keystorechange", handleKeyStoreChange);

    return () => {
      window.removeEventListener("leap_keystorechange", handleKeyStoreChange);
    };
  }, []);

  return null;
};


export const CosmostationKeyStoreChangeListener = () => {
    const { connectWallet: connectToCoreum }: any = useSigningClient();
    useEffect(() => {
      const handleKeyStoreChange = async () => {
        console.log(
          "Key store in Cosmostation is changed. You may need to refetch the account info."
        );
        const wallet_type = window.localStorage.getItem("wallet_type");
        if (wallet_type) {
          if (wallet_type === "cosmostation") {
            await connectToCoreum(wallet_type);
          }
        }
      };
  
      window.addEventListener("cosmostation_keystorechange", handleKeyStoreChange);
  
      return () => {
        window.removeEventListener("cosmosation_keystorechange", handleKeyStoreChange);
      };
    }, []);
  
    return null;
  };