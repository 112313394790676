import { Link } from "react-router-dom";
import styles from "./Item.module.sass";
import Image from "components/Image";
import { useEffect, useState } from "react";
import { config } from "app/config";
import { notBroken } from "app/methods";
import defaultAvatar from "images/default_avatar.png";
import defaultLogo from "images/default_logo.png";
type ItemProps = {
  item: any;
};

const Item = ({ item }: ItemProps) => {
  const defaultImage = item.url?.startsWith("/profile")
    ? defaultAvatar
    : defaultLogo;
  const [validAvatar, setValidAvatar] = useState("");
  useEffect(() => {
    const validateImage = async () => {
      const result = (await notBroken(
        `${config.UPLOAD_URL}uploads/${item.image}`
      ))
        ? item.avatar?.includes("http")
          ? item.avatar
          : `${config.API_URL}uploads/${item.image}`
        : defaultImage;
      setValidAvatar(result);
    };
    validateImage();
  }, [item?.image]);
  return (
    <Link to={item.url}>
      <span className={styles.item}>
        <div className={styles.preview}>
          <Image src={validAvatar} alt="Result" />
        </div>
        <div className={styles.details}>
          <div className={styles.title}>{item.title}</div>
          {item.buy && (
            <div className={styles.content}>
              <span>Buy now</span> {item.buy}
            </div>
          )}
          {item.reserve && (
            <div className={styles.content}>
              <span>Buy now</span> {item.reserve}
            </div>
          )}
          {item.login && (
            <div className={styles.content}>
              <span>@</span> {item.login}
            </div>
          )}
        </div>
      </span>
    </Link>
  );
};

export default Item;
