import { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./ConnectWallet.module.sass";
import Logo from "components/Logo";
import Arrow from "components/Arrow";
import Icon from "components/Icon";
import ChooseWallet from "./ChooseWallet";
import ScanToConnect from "./ScanToConnect";
import Message from "./Message";
import { PLATFORM_NETWORKS, RPC_URLs, config } from "app/config";
import {
  changeGlobalProvider,
  changeMemberOrNot,
  changeNetworkSymbol,
  changeWalletAddress,
  changeWalletStatus,
  selectCurrentNetworkSymbol,
  selectCurrentWallet,
  selectWalletStatus,
} from "app/reducers/auth.reducers";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import axios from "axios";
import { useSigningClient } from "app/cosmwasm";
// import md5 from "md5";
// import SearchAutocomplete from "./SearchAutocomplete";
import { useAppDispatch, useAppSelector } from "app/hooks";

import { useWalletOperations } from "hooks/useWalletOperations";
import { toast } from "react-toastify";
import {
  changeNetwork,
  isSupportedNetwork,
} from "InteractWithSmartContract/interact";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { providerOptions } from "InteractWithSmartContract/providerOptions";
import { xumm } from "utils/xummsdk";
import { useAppKit } from '@reown/appkit/react';

interface ConnectorUpdate {
  chainId: any;
}
export const web3Modal =
  typeof window !== "undefined"
    ? new Web3Modal({
        network: "mainnet",
        cacheProvider: false,
        disableInjectedProvider: false,
        providerOptions,
      })
    : null;

const wallets = {
  0: [
    {
      title: "Keplr",
      image: "/images/meta-mask.svg",
      id: "keplr",
    },
    {
      title: "Leap",
      image: "/images/wallet-connect.svg",
      id: "leap",
    },
    /*{
      title: "Cosmostation",
      image: "/images/wallet-connect.svg",
      id: "cosmostation",
    },*/
    {
      title: "WalletConnect",
      image: "/images/wallet-connect.svg",
      id: "walletconnect",
    },
  ],
  1: [
    {
      title: "MetaMask",
      image: "/images/meta-mask.svg",
      id: "metamask",
    },
    {
      title: "WalletConnect",
      image: "/images/wallet-connect.svg",
      id: "walletconnect",
    },
  ],
  2: [
    {
      title: "MetaMask",
      image: "/images/meta-mask.svg",
      id: "metamask",
    },
    {
      title: "WalletConnect",
      image: "/images/wallet-connect.svg",
      id: "walletconnect",
    },
  ],
  3: [
    {
      title: "MetaMask",
      image: "/images/meta-mask.svg",
      id: "metamask",
    },
    {
      title: "WalletConnect",
      image: "/images/wallet-connect.svg",
      id: "walletconnect",
    },
  ],
  4: [
    {
      title: "MetaMask",
      image: "/images/meta-mask.svg",
      id: "metamask",
    },
    {
      title: "WalletConnect",
      image: "/images/wallet-connect.svg",
      id: "walletconnect",
    },
  ],
  5: [
    {
      title: "XRPL",
      image: "/images/meta-mask.svg",
      id: "xrpl",
    },
  ],
};
const tabs = ["Coreum", "Ethereum", "BSC", "Polygon", "Avalanche", "XRPL"];
type ConnectWalletProps = {
  onClickLogo?: any;
  onContinue?: any;
  collections?: any;
  items?: any;
  users?: any;
};
export const infura_Id = "84842078b09946638c03157f83405213";
const ConnectWallet = ({
  onClickLogo,
  onContinue,
  collections,
  items,
  users,
}: ConnectWalletProps) => {
  const { open, close } = useAppKit()
  const [cookies, setCookies] = useState<boolean>(false);
  const [scan, setScan] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);
  const [wallet, setWalletType] = useState<string>("");
  const dispatch = useAppDispatch();
  const {
    client,
    signingClient,
    loadClient,
    connectWallet: connectToCoreum,
    disconnect: disconnectFromCoreum,
  }: any = useSigningClient();
  // useEffect(()=>loadClient())
  const walletStatus = useAppSelector(selectWalletStatus);
  const currentNetworkSymbol = useAppSelector(selectCurrentNetworkSymbol);
  const walletAddress = useAppSelector(selectCurrentWallet);
  const [xSDK, setXummSDK] = useState(null);
  const [visibleWalletModal, setVisibleWalletModal] = useState(false);
  const [showSplash, setShowSplash] = useState(false);
  const openWalletModal = () => setVisibleWalletModal(true);
  const closeWalletModal = () => setVisibleWalletModal(false);
  const { Login } = useWalletOperations();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const loginXumm = () => {
    xSDK
      .authorize()
      .then((res: any) => {
        if (res.me) {
          dispatch(changeWalletAddress(res?.me?.sub));
        }
        dispatch(changeWalletStatus(true));
        dispatch(changeNetworkSymbol(PLATFORM_NETWORKS.XRPL));
      })
      .catch((err: any) => {
        console.log("error with auth", err);
      });
  };

  useEffect(() => {
    xumm.then(async (xummSDK) => {
      // console.log("loginXumm xummSDK", xummSDK.runtime);
      if (!xummSDK.runtime) return;
      const xrt = { ...xummSDK.runtime };
      setXummSDK(xummSDK);

      xummSDK.environment.bearer
        ?.then((r) => {
          console.log("bearer", r);
        })
        .catch((err) => {
          console.log("error with bearer", err);
        });
      if (xrt.xapp) {
        // console.log("XAPP in App");
        xummSDK.environment.ott?.then((r) => console.log("ott App", r));
      }

      if (xrt.browser && !xrt.xapp) {
        // console.log("WEBAPP in App");
        xummSDK.environment.openid?.then((r) => {
          console.log("openid App", r);
        });
      }
    });
  }, []);
  const authenticate = async (wallet_type: any) => {
    //console.log("wallet_type", wallet_type)
    await connectToCoreum(wallet_type);
  };
  const handleWalletOption = (id: any) => {
    //console.log(id);
    if (id === "keplr") {
      authenticate("keplr");
    } else if (id === "leap") {
      authenticate("leap");
    } else if (id === "cosmostation") {
      authenticate("cosmostation");
    } else if (id === "walletconnect" || id === "metamask") {
      //handleWalletConnect();
      open({ view: 'Connect' })
    } else if (id === "metamask") {
      handleMetaMask();
    }
  };

  let previousNetworkSymbol = currentNetworkSymbol;

  const handleSelectNetwork = async (networkSymbol: any) => {
    previousNetworkSymbol = currentNetworkSymbol;

    if (networkSymbol === PLATFORM_NETWORKS.COREUM) {
      dispatch(changeNetworkSymbol(networkSymbol));
    } else if (networkSymbol === PLATFORM_NETWORKS.XRPL) {
      disconnectFromCoreum();
      await loginXumm();
    } else {
      disconnectFromCoreum();
      dispatch(changeNetworkSymbol(networkSymbol));
    }
  };
  useEffect(() => {
    const fetchNetwork = async () => {
      await handleSelectNetwork(activeIndex);
    };

    //fetchNetwork();
    console.log(currentNetworkSymbol);
  }, [activeIndex, currentNetworkSymbol]);
  const handleWalletConnect = async () => {
    try {
      const walletconnect = new WalletConnectConnector({
        rpc: RPC_URLs,
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        infuraId: infura_Id,
      });

      let connector_update = await walletconnect.activate();

      if (
        RPC_URLs.keys.filter((item: any) => {
          return item === connector_update.chainId;
        }).length === 0
      ) {
        walletconnect.deactivate();
        localStorage.removeItem("walletconnect");
        dispatch(changeWalletAddress(""));
        return;
      }

      if (
        !Object.keys(RPC_URLs).includes(
          (connector_update as ConnectorUpdate).chainId.toString()
        )
      ) {
        walletconnect.deactivate();
        localStorage.removeItem("walletconnect");
        dispatch(changeWalletAddress(""));
        return;
      }

      const provider = connector_update.provider;

      const account: any = connector_update.account;

      dispatch(changeWalletAddress(account));
      isCommunityMember(account);

      dispatch(changeGlobalProvider(provider));
    } catch (error) {
      console.log(error);
      dispatch(changeWalletAddress(""));
    }
  };
  const onClickChangeEVMNetwork = async (networkSymbol: any) => {
    try {
      let switchingResult = false;
      let result = await changeNetwork(networkSymbol);
      if (result) {
        if (result.success === true) {
          dispatch(changeNetworkSymbol(networkSymbol));
          switchingResult = true;
        } else {
          toast.warning(
            <div>
              <span>{result.message}</span>
              <br></br>
              <span>
                Please check your wallet. Try adding the chain to Wallet first.
              </span>
            </div>
          );
        }
      }
      return switchingResult;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onClickConnectEVMWallet = async () => {
    try {
      const provider = await web3Modal?.connect();

      const web3 = new Web3(provider);

      const accounts = await web3.eth.getAccounts();

      if (accounts[0]) {
        dispatch(changeWalletAddress(accounts[0]));
        isCommunityMember(accounts[0]);
      } else {
        dispatch(changeWalletAddress(""));
        dispatch(changeMemberOrNot(false));
      }
      dispatch(changeGlobalProvider(provider));
    } catch (error) {
      console.log(error);
      dispatch(changeWalletAddress(""));
    }
  };

  const handleMetaMask = async () => {
    let switchingResult = await onClickChangeEVMNetwork(currentNetworkSymbol);
    if (
      switchingResult === false &&
      isSupportedNetwork(previousNetworkSymbol) === true
    ) {
      handleSelectNetwork(previousNetworkSymbol);
    }
    if (switchingResult === true) onClickConnectEVMWallet();
  };

  const isCommunityMember = (walletAddress: string) => {
    try {
      axios
        .post(`${config.baseUrl}users/isCommunityMember`, {
          wallet: walletAddress || "",
        })
        .then((response) => {
          let isM = response.data.data || false;
          dispatch(changeMemberOrNot(isM));
        });
    } catch (error) {
      console.log("isM error ===> ", error);
      dispatch(changeMemberOrNot(false));
    }
  };
  return (
    <div className={styles.row}>
      <div
        className={styles.col}
        style={{
          backgroundColor:
            (scan && "#B9A9FB") || (message && "#DBFF73") || "#BCE6EC",
        }}
      >
        <Logo className={styles.logo} onClick={onClickLogo} />
        <div className={styles.line}>
          <h1 className={cn("h1", styles.title)}>Connect wallet.</h1>
          <Arrow className={styles.arrow} color="#F7FBFA" />
        </div>
        <div className={styles.info}>
          {message
            ? "Sign the message in your wallet to continue"
            : "Choose how you want to connect. There are several wallet providers."}
        </div>
      </div>
      <div className={styles.col}>
        {message ? (
          <>
            <button
              className={cn("button-circle", styles.back)}
              onClick={() => setMessage(false)}
            >
              <Icon name="arrow-left" />
            </button>
            <Message
              onContinue={() => {
                onContinue();
                handleWalletOption(wallet);
              }}
              onCancel={onClickLogo}
            />
          </>
        ) : scan ? (
          <>
            <button
              className={cn("button-circle", styles.back)}
              onClick={() => setScan(false)}
            >
              <Icon name="arrow-left" />
            </button>
            <ScanToConnect />
          </>
        ) : (
          <ChooseWallet
            onScan={() => setScan(true)}
            onClickWallet={(id: string) => {
              setMessage(true);
              setWalletType(id);
            }}
            wallets={wallets}
            tabs={tabs}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
        {!message && (
          <div
            className={cn(styles.cookies, {
              [styles.hide]: cookies,
            })}
          >
            <div className={styles.text}>
              We use 🍪 <span>cookies</span> for better experience
            </div>
            <button className={styles.accept} onClick={() => setCookies(true)}>
              Accept
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectWallet;
