import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./NavLink.module.sass";
import Icon from "components/Icon";

type NavLinkProps = {
    className?: string;
    activeClassName?: any;
    href: string;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const NavLink = ({
    className,
    activeClassName,
    href,
    children,
    onClick
}: NavLinkProps) => {
    const location = useLocation();  // Replacing useRouter with useLocation

    return (
        <Link to={href} onClick={onClick}>
            <span
                className={cn("h3", styles.link, className, {
                    [styles.active]: location.pathname === href,  // Check if current path matches the link
                })}
            >
                <div className={styles.title}>
                    {children}
                    <span className={styles.hover}>{children}</span>
                </div>
                <Icon name="arrow-right" />
            </span>
        </Link>
    );
};

export default NavLink;
