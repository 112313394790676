import { Suspense, lazy, useMemo, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Footer from "components/Footer";
import Header from "components/Header";
import { SigningCosmWasmProvider } from "../app/cosmwasm.js";
import { Backdrop, CircularProgress } from "@mui/material";
import { FaAngleUp } from "react-icons/fa";
import EmptyPage from "containers/EmptyPage";
import {
  CosmostationKeyStoreChangeListener,
  KeplrKeyStoreChangeListener,
  LeapKeyStoreChangeListener,
} from "app/walletListeners";
import { createAppKit } from "@reown/appkit/react";
import { useAppSelector } from "app/hooks";
import { WagmiProvider } from "wagmi";
import {
  arbitrum,
  mainnet,
  bsc,
  polygon,
  avalanche,
  solana,
  hedera,
  defineChain
} from "@reown/appkit/networks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { selectCurrentNetworkSymbol } from "app/reducers/auth.reducers";
import { config, EVM_MAINNETS_LIST } from "app/config";
import axios from "axios";
const queryClient = new QueryClient();
const projectId = "d128ea428f2ca9586b32d969a33c9e43";
const metadata = {
  name: "RIZENFT",
  description: "RIZE NFT",
  url: "https://rize2day.com",
  icons: ["https://rize2day.com/favicon.png"],
};
const coreum = defineChain({
  id: EVM_MAINNETS_LIST[1].id,
  caipNetworkId: `eip155:${config.CHAIN_ID}`,
  chainNamespace: 'eip155',
  name: config.CHAIN_NAME,
  nativeCurrency: {
    decimals: config.COIN_DECIMALS,
    name: EVM_MAINNETS_LIST[1].currency,
    symbol: EVM_MAINNETS_LIST[1].currency,
  },
  rpcUrls: {
    default: {
      http: [EVM_MAINNETS_LIST[1].rpcURL],
      webSocket: ['WS_RPC_URL'],
    },
  },
  blockExplorers: {
    default: { name: 'Explorer', url: EVM_MAINNETS_LIST[1].blockScanUrl },
  },
  contracts: {
  }
});
const networks = [mainnet, arbitrum, bsc, polygon, avalanche, solana, hedera, coreum];

const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
  ssr: true,
});

const modal = createAppKit({
  adapters: [wagmiAdapter],
  networks: [mainnet, arbitrum, bsc, polygon, avalanche, solana, hedera, coreum],
  projectId,
  metadata,
  features: {
    analytics: true,
  },
});

export function AppKitProvider({ children }) {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
const PageHome = lazy(() => import("containers/PageHome/PageHome"));
const PageUploadItem = lazy(() => import("containers/PageUploadItem"));
const PageUploadMultipleItem = lazy(
  () => import("containers/PageUploadMultipleItem")
);
const DetailNFT = lazy(() => import("containers/NftDetailPage/DetailNFT"));
const PageSearch = lazy(() => import("containers/PageSearch"));
const AuthorPage = lazy(() => import("containers/AuthorPage/AuthorPage"));
const ProfilePage = lazy(() => import("containers/ProfilePage"));
const MyProfilePage = lazy(() => import("containers/ProfilePage/MyProfile"));
//const AccountPage = lazy(() => import("containers/AccountPage/AccountPage"));
const UploadVariants = lazy(() => import("containers/UploadVariants"));
const PageMessage = lazy(() => import("containers/PageMessage/PageMessage"));
const Admin = lazy(() => import("containers/Admin"));
const CollectionList = lazy(
  () => import("containers/Collections/collectionList")
);
const ItemsOfCollection = lazy(
  () => import("containers/Collections/ItemsOfCollection")
);
const CreateCollection = lazy(
  () => import("containers/Collections/createCollection")
);
const EditCollection = lazy(
  () => import("containers/Collections/editCollection")
);
const Activity = lazy(() => import("containers/Activity"));
const PageFAQ = lazy(() => import("containers/FaqPage/FAQ"));
const PageLaunchPad = lazy(() => import("containers/LaunchPad/launchpadpage"));
const LaunchPadSetting = lazy(
  () => import("containers/LaunchPad/launchpadSetting")
);
const LaunchPadHome = lazy(
  () => import("containers/LaunchPad/launchpadhomepage")
);

const Create = lazy(() => import("containers/Create/CreatePage"));
const CreateCongratsPage = lazy(
  () => import("containers/Create/CreateCongratsPage")
);
const CreateCollection1 = lazy(() => import("containers/Create"));
const NotificationPage = lazy(() => import("containers/NotificationPage"));
const SettingsPage = lazy(() => import("containers/SettingsPage"));
const CreateWithCollectionPage = lazy(
  () => import("containers/Create/CreateWithCollectionPage")
);
const NftDetailPage = lazy(() => import("containers/NFT/NFTDetailPage"));
export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/#", component: PageHome },
  { path: "/upload-single", component: PageUploadItem },
  { path: "/upload-multiple", component: PageUploadMultipleItem },
  { path: "/nft-detail/:tokenId", component: NftDetailPage },
  { path: "/nft/:tokenId", component: DetailNFT },
  { path: "/page-search", component: PageSearch },
  { path: "/page-author/:userId", component: AuthorPage },
  { path: "/profile/:userId", component: ProfilePage },
  { path: "/my-profile", component: MyProfilePage },
  { path: "/account", component: SettingsPage },
  { path: "/page-upload-item", component: UploadVariants },
  { path: "/message/:userId", component: PageMessage },
  { path: "/message", component: PageMessage },
  { path: "/admin", component: Admin },
  { path: "/create", component: Create },
  { path: "/create/create-collection", component: CreateCollection1 },
  { path: "/create/with-collection", component: CreateWithCollectionPage },
  { path: "/create/congrats", component: CreateCongratsPage },
  { path: "/collectionList", component: CollectionList },
  { path: "/collectionItems/:collectionId", component: ItemsOfCollection },
  { path: "/createCollection", component: CreateCollection },
  { path: "/editCollection/:collectionId", component: EditCollection },
  { path: "/activity", component: Activity },
  { path: "/faq", component: PageFAQ },
  { path: "/launchpad/:collectionId", component: PageLaunchPad },
  { path: "/launchpadSetting/:collectionId", component: LaunchPadSetting },
  { path: "/launchpad", component: LaunchPadHome },
  { path: "/notification", component: NotificationPage },
  { path: "/settings", component: SettingsPage },
];

const MyRoutes = ({ mode }) => {
  const [collections, setCollections] = useState([]);
  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
        components: {
          MuiCheckbox: {
            styleOverrides: {
              root: {
                color: mode === "dark" ? "white" : "black",
              },
            },
          },
        },
      }),
    [mode]
  );
  const currentNetworkSymbol = useAppSelector(selectCurrentNetworkSymbol);

  useEffect(() => {
    /*switch (currentNetworkSymbol) {
      case 2:
        modal.switchNetwork(mainnet);
        break;
      case 3:
        modal.switchNetwork(bsc);
        break;
    }*/
  }, [currentNetworkSymbol]);
  const fetchData = async () => {
    console.log("fetching.....")
    try {
      const resp = await axios.get(`${config.API_URL}api/collection/search`);
      setCollections(resp.data.collections);
      setItems(resp.data.items);
      setUsers(resp.data.usersData);
    } catch (err) {
      console.log("Error Search Items: ", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const onClickScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <BrowserRouter>
      <SigningCosmWasmProvider>
        <AppKitProvider>
          <ThemeProvider theme={theme}>
            <KeplrKeyStoreChangeListener />
            <LeapKeyStoreChangeListener />
            <CosmostationKeyStoreChangeListener />

            <ScrollToTop />
            <Header />
            <Suspense
              fallback={
                <div>
                  <div
                    style={{
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></div>
                  <Backdrop
                    sx={{
                      color: "#ffffff3f",
                      backgroundColor: "#000000cc",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={true}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              }
            >
              <Routes>
                {pages.map(({ component: Component, path }) => (
                  <Route key={path} element={<Component />} path={path} />
                ))}
                <Route path="*" element={<EmptyPage />} />
              </Routes>
            </Suspense>
            <Footer />
            <FaAngleUp
              className="icon-position icon-style bg-[black] hover:text-[#33FF00]"
              onClick={onClickScrollTop}
            ></FaAngleUp>
          </ThemeProvider>
        </AppKitProvider>
      </SigningCosmWasmProvider>
    </BrowserRouter>
  );
};

export default MyRoutes;
