import { useState } from "react";
import cn from "classnames";
import styles from "./ChooseWallet.module.sass";
import Icon from "components/Icon";
import Image from "components/Image";

type ChooseWalletProps = {
  onScan?: () => void;
  onClickWallet?: (id: string) => void;
  wallets?: any;
  tabs?: any;
  activeIndex?:any;
  setActiveIndex?: any;
};

const ChooseWallet = ({
  onClickWallet,
  onScan,
  wallets,
  tabs,
  activeIndex,
  setActiveIndex,
}: ChooseWalletProps) => {
 

  return (
    <div className={styles.choose}>
      <div className={cn("h3", styles.title)}>Choose the wallet</div>
      <div className={styles.head}>
        <div className={styles.tabs}>
          {tabs.map((item, index) => (
            <button
              className={cn(styles.tab, {
                [styles.active]: activeIndex === index,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.wallets}>
        {(wallets[activeIndex as keyof typeof wallets] || []).map(
          (wallet, index) => (
            <button
              className={styles.wallet}
              key={index}
              onClick={() => {
                onClickWallet(wallet.id);
                //handleWalletOption(wallet.id);
              }}
            >
              <span className={styles.inner}>
                <span className={styles.icon}>
                  <Image
                    src={wallet.image}
                    width={40}
                    height={40}
                    alt="Wallet"
                  />
                </span>
                {wallet.title} <Icon name="arrow-right" />
              </span>
            </button>
          )
        )}
      </div>
      {
        <div className={styles.btns}>
          <button className={styles.scan} onClick={onScan}>
            Scan to connect
          </button>
        </div>
      }
    </div>
  );
};

export default ChooseWallet;
