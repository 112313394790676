import { useState } from "react";
import cn from "classnames";
import styles from "./Image.module.sass";

const Image = ({
  className,
  src,
  alt,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={`${styles.imageWrapper} ${className}`}>
      <img
        className={cn(styles.image, { [styles.loaded]: loaded }, "w-full h-full")}
        onLoad={() => setLoaded(true)}
        src={src}
        alt={alt}
        {...props}
        style={{
          position: "absolute",
          inset: "0px",
          boxSizing: "border-box",
          padding: 0,
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default Image;
