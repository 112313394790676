import { useEffect, useRef, useState } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Image from "components/Image";
import NavLink from "components/NavLink";
import Icon from "components/Icon";
import Wallet from "./Wallet";
import { selectCurrentUser } from "app/reducers/auth.reducers";
import { useAppSelector } from "app/hooks";
import { notBroken } from "app/methods";
import { config } from "app/config.js";
import defaultAvatar from "images/default_avatar.png";
type ProfileProps = {
  className?: string;
  headClassName?: string;
  bodyClassName?: string;
  visible: any;
  onOpen: () => void;
  onClose: () => void;
};

const Profile = ({
  className,
  headClassName,
  bodyClassName,
  onOpen,
  onClose,
  visible,
}: ProfileProps) => {
  const initialRender = useRef(true);
  const globalUser = useAppSelector(selectCurrentUser);
  const menu = [
    {
      title: "My profile",
      url: `/my-profile`,
    },
    {
      title: "Edit profile",
      url: "/account",
    },
    {
      title: "Messages",
      url: "/message",
    },
    {
      title: "My Collections",
      url: "/collectionList",
    },
    {
      title: "Create",
      url: "/createCollection",
    },
    {
        title: "Activity Log",
        url: "/activity",
    }
  ];
  const [validAvatar, setValidAvatar] = useState("");

  useEffect(() => {
    const validateImage = async () => {
      //console.log(globalUser)
      const result = (await notBroken(
        `${config.UPLOAD_URL}uploads/${globalUser?.avatar}`
      ))
        ? globalUser?.avatar?.includes("http")
          ? globalUser?.avatar
          : `${config.API_URL}uploads/${globalUser?.avatar}`
        : defaultAvatar;
      setValidAvatar(result);
    };
    validateImage();
  }, [globalUser]);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      visible ? disablePageScroll() : enablePageScroll();
    }
  }, [visible]);

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div
        className={cn(styles.profile, { [styles.active]: visible }, className)}
      >
        <button className={cn(styles.head, headClassName)} onClick={onOpen}>
          <Image src={validAvatar} alt="Avatar" />
        </button>
        <div className={cn(styles.body, bodyClassName)}>
          <button
            className={cn("button-circle button-medium", styles.close)}
            onClick={onClose}
          >
            <Icon name="close-fat" />
          </button>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <Image src={validAvatar} alt="Avatar" />
            </div>
            <div className={styles.details}>
              <div className={cn("h3", styles.man)}>{`${globalUser?.full_name === undefined || globalUser?.full_name === "" ? "Anonymous" : globalUser?.full_name}`}</div>
              <div className={styles.login}>{`@${globalUser?.username === undefined || globalUser?.username === "" ? "Anonymous" : globalUser?.username}`}</div>
            </div>
          </div>
          <Wallet onDisconnect={onClose} />
          <div className={styles.menu}>
            {menu.map((link, index) => (
              <NavLink
                className={cn(styles.link)}
                activeClassName={styles.active}
                href={link.url}
                key={index}
                onClick={onClose}
              >
                {link.title}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Profile;
