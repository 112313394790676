import { useState, useEffect, useRef } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { useMediaQuery } from "react-responsive";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "components/Icon";
import Item from "./Item";
import axios from "axios";
import { config } from "app/config";
type ItemsType = {
  title: string;
  buy?: string;
  reserve?: string;
  login?: string;
  image?: string;
  url: string;
};

type ResultType = {
  title: string;
  items: ItemsType[];
};

type SearchProps = {
  className?: string;
  light?: boolean;
  collections?: any;
  items?: any;
  users?: any;
};

const Search = ({
  className,
  light,
  collections,
  items,
  users,
}: SearchProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [resultVisible, setResultVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [results, setResults] = useState<ResultType[]>([]);
  useEffect(() => {
    let r = [];
    if (collections) {
      const searchCollections = {
        title: "Collections",
        items: collections?.slice(0, 5),
      };
      r.push(searchCollections);
    }
    if (items) {
      const searchItems = {
        title: "Items",
        items: items?.slice(0, 5),
      };
      r.push(searchItems);
    }
    if (users) {
      const searchUsers = {
        title: "Users",
        items: users?.slice(0, 5),
      };
      r.push(searchUsers);
    }
    setResults(r);
  }, [items, collections, users]);
  const onChange = (e: any) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setResultVisible(false);
    } else {
      setResultVisible(true);
      handleSearch(e.target.value);
    }
  };

  function handleSearch(q: string) {
    try {
      let data = JSON.stringify({
        query: q,
      });

      let config1 = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${config.API_URL}api/search/search`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios.request(config1).then((response) => {
        console.log(response.data);
        let r = [];
        if (response?.data?.users?.length > 0) {
          const searchUsers = {
            title: "Users",
            items: response?.data?.users?.map((user) => {
              return {
                title: user.full_name,
                login: user.username,
                image: user.avatar,
                url: `/profile/${user._id}`,
              };
            }),
          };
          r.push(searchUsers);
        }
        if (response?.data?.collections?.length > 0) {
          const searchCollections = {
            title: "Collections",
            items: response?.data?.collections.map((collection) => {
              return {
                title: collection.name,
                image: collection.logoURL,
                url: `/collectionList/${collection._id}`,
                login: collection.userDetails[0]?.username,
              };
            }),
          };
          r.push(searchCollections);
        }
        if (response?.data?.items?.length > 0) {
          const searchItems = {
            title: "Items",
            items: response?.data?.items?.map((item) => {
              return {
                title: item.name,
                buy: item.price,
                image: item.thumbnailURL,
                url: `/nft/${item._id}`,
              };
            }),
          };
          r.push(searchItems);
        }
        setResults(r);
        console.log(results);
      });
    } catch (err) {
      console.log("Error Search Items: ", err);
    }
  }
  const handleToggle = () => {
    setVisible(!visible);
    setSearch("");
    setResultVisible(false);
  };

  const handleClose = () => {
    setVisible(false);
    setSearch("");
    setResultVisible(false);
  };

  const handleClear = () => {
    setSearch("");
    setResultVisible(false);
  };

  const initialRender = useRef(true);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      isDesktop && (resultVisible ? disablePageScroll() : enablePageScroll());
    }
  }, [resultVisible, isDesktop]);

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <div
        className={cn(
          styles.search,
          {
            [styles.active]: visible,
            [styles.visible]: resultVisible,
            [styles.light]: light,
          },
          className
        )}
      >
        <div className={styles.head}>
          <div className={styles.field}>
            <input
              className={`${styles.input} dark:text-white`}
              type="text"
              value={search}
              onChange={onChange}
              placeholder="Search ..."
            />
          </div>
          <button className={styles.clear} onClick={handleClear}>
            clear
          </button>
          <button className={styles.toggle} onClick={handleToggle}>
            <Icon name="search" className="dark:!fill-white" />
            <Icon name="close" className="dark:!fill-white" />
          </button>
        </div>
        <div
          className={cn(styles.result, {
            [styles.visible]: resultVisible,
          })}
        >
          {results.map((x, index) => (
            <div className={styles.group} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.list}>
                {x.items.map((item, index) => (
                  <Item item={item} key={index} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Search;
