import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import "./styles/custom.scss";
import "./index.css";
import "rc-slider/assets/index.css";
import "react-tooltip/dist/react-tooltip.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrimeReactProvider } from "primereact/api";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />

          <ToastContainer />
        </PersistGate>
      </Provider>
    </PrimeReactProvider>
  </React.StrictMode>
);
